body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  background-color: #f9f9f9;
}

body * {
  font-family: "Open Sans", sans-serif;
}

.porfolio-menu {
  display: flex;
  flex-direction: row;
  padding: 0 200px;
  justify-content: center;
  margin-bottom: 20px;
}

.porfolio-item {
  margin-left: 20px;
  font-weight: 600;
  color: #424242;
  cursor: pointer;
  position: relative;
  display: block;
}

.porfolio-item:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  left: -1px;
  right: 0;
  bottom: -5px;
  margin: 0 auto;
  opacity: 0;
  background-color: #3caea3;
  transition: opacity 0.7s, width 0.5s;
  border-radius: 5px;
}

.porfolio-item.selected:after {
  width: 70%;
  opacity: 1;
}

.porfolio-item:hover,
.porfolio-item.selected {
  color: #3caea3;
}

.porfolio-item:first-child {
  margin-left: 0;
}

.loader-container {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: auto;
  height: auto;
}

/* .loader-container.animate {
  transform: translate3d(-50%, -50%, 0) scale(31);
} */

.about-me-container {
  padding: 50px 50px 53px;
  color: rgb(66, 66, 66);
  background-color: rgb(249, 249, 249);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 70%;
  max-width: 1130px;
  margin: 0px auto;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
}

.about-me-container,
.what-i-can-offer,
.timeline-container {
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.8s, opacity 0.8s;
}

.about-me-container.animate,
.what-i-can-offer.animate,
.timeline-container.animate {
  transform: translateY(0);
  opacity: 1;
}

/* body * {
  font-family: 'Roboto', sans-serif;
  font-family: 'Open Sans', sans-serif;
} */

svg.floating-item {
  width: 50px;
}

.pic-container {
  background-color: #e2e2e2;
  /* width: 1200px;
  height: 1200px; */
  width: 756px;
  height: 890px;
  overflow: hidden;
  /* border-radius: 1000px; */
  position: absolute;
  /* right: -580px; */
  /* bottom: 70px; */
  top: 0;
  right: 0;
  /* right: -100px; */
  transform: translateX(-10%) rotate(-1deg);
  animation: pic-animation-right 1.6s 1.5s;
}

.fullpage-container.loaded .pic-container {
  transform: translateX(0) rotate(0deg);
}

/* .pic-container {
  background-color: #e2e2e2;
  width: 60%;
  height: 95%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
} */

.offers-card {
  width: 340px;
  margin: 0 20px 60px;
  /* height: 170px; */
}

.offers-text {
  font-size: 15px;
  margin-top: 15px;
}

.dev-icon {
  transform: scale(0.9);
  transition: opacity 0.3s 0.1s, transform 0.5s 0.1s;
  height: 80px;
}

.frameworks-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 0px;
}

.frameworks-icon {
  width: 30px;
  max-height: 30px;
  margin-left: 15px;
  opacity: 0;
  transform: translateY(15px) scale(0.5);
  transition: opacity 0.3s, transform 0.3s, height 0.4s;
}

.frameworks-icon.first {
  margin-left: 0;
}

.offers-card:hover .dev-icon {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.3s, transform 0.5s;
}

.offers-card:hover .frameworks-icon {
  opacity: 1;
  transform: scale(1) translateY(-3px);
}

.offers-card:hover .frameworks-icon.first {
  transition: opacity 0.4s 0.5s, transform 0.4s 0.5s;
}

.offers-card:hover .frameworks-icon.second {
  transition: opacity 0.4s 0.9s, transform 0.4s 0.9s;
}

.offers-card:hover .frameworks-icon.third {
  transition: opacity 0.4s 1.3s, transform 0.4s 1.3s;
}

.offers-card:hover .frameworks-icon.fourth {
  transition: opacity 0.4s 1.7s, transform 0.4s 1.7s;
}

.offers-card:hover .frameworks-icon.fifth {
  transition: opacity 0.4s 2.1s, transform 0.4s 2.1s;
}

.offers-card .title {
  transition: color 0.2s 0.1s, transform 0.5s 0.1s;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offers-card:hover .title {
  color: #3caea3;
  transform: translateY(-30px);
  transition: color 0.2s, transform 0.5s;
}

.contacts-icon {
  width: 30px;
  height: 30px;
}

.contacts-card {
  width: 200px;
  margin-bottom: 80px;
}

.timeline-card {
  margin-bottom: 15px;
  width: 380px;
  height: 134px;
  border-radius: 5px;
  padding: 15px 30px;
  position: relative;
  border: 1px solid #000;
  background-color: #f9f9f9;
  text-align: left;
  box-sizing: border-box;
}

.timeline-card.remove-borders {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.timeline-date-circle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  margin-top: -7px;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1s 1.2s;
}

.timeline.animate .timeline-date-circle {
  transform: scale(1);
}

.timeline-date {
  position: absolute;
  width: 40px;
  margin-left: -20px;
  font-weight: 700;
  color: #424242;
  margin-top: 7px;
  font-size: 12px;
  background-color: #f9f9f9;
  transform: translateY(-10px);
  opacity: 0;
  transition: transform 1s 1.2s, opacity 1s 1.2s;
}

.floating-container {
  position: absolute;
  display: block;
}

/* .quote {
  font-family: 'Vollkorn', serif;
} */

.fs-italic {
  font-style: italic;
}

.fs-normal {
  font-style: normal;
}

.timeline.animate .timeline-date {
  transform: translateY(0);
  opacity: 1;
}

.timeline {
  padding: 50px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 883px;
  border-radius: 25px;
  background-color: #f9f9f9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
}

.circular-progress {
  display: inline-block;
  position: relative;
  margin-bottom: 25px;
}

.fullpage-container {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
}

.tooltip-container > *:first-child:hover + .tooltip {
  transform: scale(1) translateY(-55px);
  opacity: 1;
}

.tooltip-container {
  position: relative;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  transform: scale(0.3) translateY(-70px);
  opacity: 0;
  top: 0;
  height: 40px;
  color: #f9f9f9;
  /* border: 2px solid #424242; */
  border-radius: 5px;
  background-color: #424242;
  transition: opacity 0.4s, transform 0.4s;
  line-height: 36px;
  font-size: 15px;
}

.tooltip:after {
  content: "";
  position: absolute;
  background-color: #424242;
  width: 15px;
  height: 15px;
  bottom: -7px;
  transform: rotate(45deg);
  left: -3px;
  right: 0;
  margin: 0 auto;
}

.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 200px;
  height: 765px;
  overflow-y: auto;
}

.portfolio img {
  max-height: 400px;
  max-width: 600px;
  position: relative;
  display: block;
  transition: transform 0.7s;
}

.portfolio div:hover img {
  transform: scale(1.2);
}

.timeline .timeline-card {
  opacity: 0;
  transition: all 0.5s 2s;
}
.timeline .timeline-card.left {
  transform: translateX(-50px);
}
.timeline .timeline-card.right {
  transform: translateX(50px);
}

.timeline.animate .timeline-card {
  opacity: 1;
}

.timeline.animate .timeline-card {
  transform: translateX(0);
}

.timeline-line {
  height: 0;
  position: absolute;
  width: 2px;
  background-color: rgb(228, 226, 226);
  transition: height 1s 0.5s;
  /* transition: height 1s 0.8s; */
}

.we-are-here {
  position: absolute;
  bottom: -30px;
  border: 2px solid rgb(60, 174, 163);
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  color: rgb(60, 174, 163);
  background-color: rgb(249, 249, 249);
  opacity: 0;
  transform: translateY(-10px);
  transition: transform 0.5s 1.6s, opacity 0.5s 1.6s;
}

.timeline.animate .we-are-here {
  opacity: 1;
  transform: translateY(0);
}

.timeline.animate .timeline-line {
  height: 100%;
}

.timeline-scroller {
  min-height: 280px;
  /* max-height: 695px; */
  width: 880px;
  margin: 0 auto;
  /* overflow-y: auto;
  overflow-x: hidden; */
  overflow: visible;
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
}

.portfolio > div {
  margin: 10px;
  border: 3px solid #424242;
  overflow: hidden;
  position: relative;
  border-radius: 9px;
  -webkit-animation: portfolioItem 0.2s linear; /* Safari 4+ */
  -moz-animation: portfolioItem 0.2s linear; /* Fx 5+ */
  -o-animation: portfolioItem 0.2s linear; /* Opera 12+ */
  animation: portfolioItem 0.2s linear; /* IE 10+, Fx 29+ */
  /* max-width: 1200px; */
  /* max-height: 1200px; */
  /* opacity:1; */
  /* transition: opacity 1s, border-radius .2s, max-width 0.8s, max-height 0.8s; */
}

.menu {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* width: calc(100% + 20px); */
  width: 100%;
  padding: 25px;
  padding-left: 130px;
  margin-left: -10px;
  box-sizing: border-box;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.4s, box-shadow 0.4s, height 0.4s, padding 0.2s;
  height: auto;
}

.hamburguer-button {
  cursor: pointer;
  position: absolute;
  left: 70px;
  top: 15px;
}

.menu.hamburguer-mode {
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  height: 0;
  background-color: transparent;
}

.menu.hamburguer-mode.open {
  height: 336px;
  background-color: #eee;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
}

.menu.hamburguer-mode .menu-item {
  margin-bottom: 20px;
  margin-left: 0;
}

.menu.hamburguer-mode .menu-item:nth-child(2) {
  margin-top: 21px;
}

.menu.focus {
  background-color: #f9f9f9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
}

.menu .menu-item {
  /* transition: all .3s; */
  display: inline-block;
  text-transform: uppercase;
  margin-left: 50px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  cursor: pointer;
  transition: color 0.5s;
}

.menu .menu-item.selected,
.menu .menu-item:hover {
  color: #3caea3;
}

.menu .menu-item:after {
  width: 0;
  height: 2px;
  background-color: #3caea3;
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.7s, width 0.5s;
}

.menu .menu-item.selected:after,
.menu .menu-item:hover:after {
  width: 70%;
  opacity: 1;
}

.menu .menu-item:first-child {
  margin-left: 0;
}

/* .portfolio > div.removed {
  max-width: 0;
  max-height:0;
  border:0;
  opacity: 0;
} */

.hamburguer-line {
  height: 4px;
  background-color: #5d5d5d;
  margin-bottom: 5px;
  border-radius: 50px;
  transition: 0.6s;
  opacity: 1;
}

.hamburguer-button:hover .hamburguer-line {
  background-color: #3caea3;
}

.hamburguer-line.line-1 {
  width: 22px;
}

.hamburguer-line.line-2 {
  width: 25px;
}

.hamburguer-line.line-3 {
  width: 15px;
  margin-bottom: 0;
}

.hamburguer-mode.open .hamburguer-button .hamburguer-line.line-1 {
  width: 27px;
  transform: rotate(45deg) translate3d(6px, 8px, 0);
}

.hamburguer-mode.open .hamburguer-button .hamburguer-line.line-2 {
  width: 27px;
  transform: rotate(-45deg) translate3d(-1px, 0px, 0);
}

.hamburguer-mode.open .hamburguer-button .hamburguer-line.line-3 {
  width: 0px;
  opacity: 0;
}

.counter-container-section {
  display: flex;
  justify-content: space-evenly;
  height: 250px;
  background-color: rgb(66, 66, 66);
  padding: 0 15%;
  align-items: center;
}

.counter-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  height: 120px;
  justify-content: space-around;
  align-items: center;
}

.counter-number {
  color: #3caea3;
  font-weight: 700;
  font-size: 37px;
}

.counter-text {
  font-size: 20px;
  color: #e0e0e0;
}

.counter-image .hours {
  width: 34px;
}

.counter-image .lines {
  width: 42px;
}

.counter-image .coffees {
  width: 39px;
  margin-left: 13px;
}

.gallery {
  margin-top: 50px;
  display: flex;
  padding: 0 100px;
  flex-wrap: wrap;
}

.gallery-column {
  padding: 0 8px;
  /* flex: 25%; */
  /* max-width: 25%; */
}

.gallery-column .image {
  border-radius: 7px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: 0.1s all;
  box-shadow: 0 1px 6px 1px #cacaca;
  cursor: pointer;
  opacity: 0;
  transform: scale(0.3);
  position: relative;
}

.gallery.animate .gallery-column .image {
  animation: gallery-image 0.6s;
  opacity: 1;
  transform: scale(1);
}

.gallery .gallery-column .image:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
  transform: scale(1.1) translateY(-10px);
  transition: 0.4s all;
  z-index: 1;
}

.gallery-column .image img {
  width: 100%;
  vertical-align: middle;
  object-fit: contain;
  max-height: 370px;
  background-color: #eee;
}

.gallery-previewer {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  /* padding: 5%; */
  top: 0;
  left: 0;
  box-sizing: border-box;
  /* background-color: #1944408f; */
  background-color: #0000008f;
  z-index: 10;
  animation: galleryPreviewShadow 0.4s;
}

@keyframes galleryPreviewShadow {
  from {
    background-color: transparent;
  }
  to {
    background-color: #0000008f;
  }
}

.gallery-previewer-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 90%;
  /* height: 90%; */
  max-width: 1260px;
  max-height: 797px;
  position: absolute;
  border-radius: 9px;
  left: 50%;
  top: 50%;
  transform-origin: bottom left;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
  animation: galleryPreviewContainer 0.4s;
}

.gallery-previewer-header {
  flex: 0 0 50px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3f3f3;
  background-color: #424242;
  font-size: 18px;
  font-weight: 700;
}

.gallery-previewer-content {
  flex: 1 1 auto;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.gallery-previewer-content img {
  max-height: 100%;
  max-width: 100%;
}

.my-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px,
    rgba(0, 0, 0, 0.024) 0px -6px 16px -6px;
}

.my-picture img {
  width: 120px;
  margin-top: -13px;
}

.footer {
  padding: 25px 50px;
  color: rgb(249, 249, 249);
  background-color: rgb(66, 66, 66);
  height: 130px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.social-media-container a {
  margin: 0 5px;
}

.social-media-container a svg path {
  fill: #f9f9f9;
  transition: 0.4s;
}

.social-media-container a:hover svg path {
  fill: #3caea3;
}

.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-type-1 {
  background-color: #5a819e;
}

.badge-type-2,
.badge-type-9 {
  background-color: #f67e7d;
  color: #f9f9f9;
}

.badge-type-3 {
  background-color: #689f8f;
  color: #f9f9f9;
}

.badge-type-4,
.badge-type-10 {
  background-color: #a391d6;
  color: #f9f9f9;
}

.badge-type-5 {
  background-color: #ffc811;
}

.badge-type-6 {
  background-color: #6cbb64;
  color: #f9f9f9;
}

.badge-type-7 {
  background-color: #475d6f;
  color: #f9f9f9;
}

.badge-type-8 {
  background-color: #7f395e;
  color: #f9f9f9;
}

.my-roles {
  margin-right: 10px;
}

.badge {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 200px;
  padding: 2px 10px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: 600;
  display: inline-block;
  line-height: 15px;
}

.col .badge:nth-child(2) {
  margin-left: 0;
}

.gallery-previewer-info {
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto;
  flex-direction: row;
  padding-right: 25px;
  overflow: hidden;
  flex-wrap: wrap;
}

.gallery-previewer-info .col {
  flex: 0 0 33%;
  padding-top: 15px;
  padding-left: 75px;
  box-sizing: border-box;
  text-align: left;
}

.gallery-previewer-info .col .title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.gallery-previewer-info .col .text {
  font-size: 14px;
}

.link {
  margin-top: 15px;
  font-size: 14px;
  color: #85e3ff;
  /* opacity: 0;
  transition: opacity .4s; */
  display: block;
}

/* .portfolio div:hover .grey-layer > * {
   opacity: 1;
 } */

.text-cursor {
  display: inline-block;
  width: 2px;
  height: 25px;
  margin-left: 3px;
  top: 5px;
  position: relative;
  background-color: #5d5d5d;
}
.text-cursor.is-not-typing {
  -webkit-animation: flashColor 0.6s linear infinite alternate; /* Safari 4+ */
  -moz-animation: flashColor 0.6s linear infinite alternate; /* Fx 5+ */
  -o-animation: flashColor 0.6s linear infinite alternate; /* Opera 12+ */
  animation: flashColor 0.6s linear infinite alternate; /* IE 10+, Fx 29+ */
}

.title {
  font-size: 25px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #424242;
}

.form {
  padding: 30px;
  width: 560px;
  height: 99px;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: height 1.2s;
  box-sizing: border-box;
}

.form input,
.form textarea {
  font-size: 13px;
}

.form.open {
  height: 715px;
}

.button-idle {
  width: 36px;
  height: 36px;
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  background-color: #424242;
  /* transform: scale(1); */
  /* transition: transform 1s; */
  transform: translateY(0);
  transition: width 1.5s, height 1.5s, transform 1.5s;
}

.button-idle.open {
  /* transform: scale(43); */
  width: 1500px;
  height: 1500px;
  transform: translateY(-750px);
}

.button {
  padding: 10px 30px;
  text-decoration: none;
  background-color: #5d5d5d;
  color: #f9f9f9;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  transition: font-size 0.4s;
  /* border: none;
  outline: none; */
}

.button.open {
  font-size: 17px;
  background-color: #424242;
  cursor: auto;
}

.button:hover {
  background-color: #424242;
}

.button.white {
  background-color: #f9f9f9;
  color: #424242;
}

.button.white:hover {
  background-color: #dedede;
}

input,
textarea {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
}

textarea {
  resize: none;
  height: 300px;
}

.submission-form input,
.submission-form textarea {
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 0.5s 0.8s, box-shadow 0.3s;
}

.submission-form .button {
  opacity: 0;
  transition: opacity 0.5s 0.8s;
}

.submission-form.open textarea,
.submission-form.open input,
.submission-form.open .button {
  opacity: 1;
}

input:first-child {
  margin-top: 30px;
}

.primary-background {
  background-color: #1f272b;
}

.secondary-background {
  background-color: #d77a61;
}

.primary-color {
  color: #1f272b;
}

.secondary-color {
  color: #d77a61;
}

.floating-icon {
  position: absolute;
  -webkit-animation: floatIcon 8s linear infinite; /* Safari 4+ */
  -moz-animation: floatIcon 8s linear infinite; /* Fx 5+ */
  -o-animation: floatIcon 8s linear infinite; /* Opera 12+ */
  animation: floatIcon 8s linear infinite; /* IE 10+, Fx 29+ */
}

.floating-icon-medium {
  position: absolute;
  -webkit-animation: floatIcon 8s linear infinite 2s; /* Safari 4+ */
  -moz-animation: floatIcon 8s linear infinite 2s; /* Fx 5+ */
  -o-animation: floatIcon 8s linear infinite 2s; /* Opera 12+ */
  animation: floatIcon 8s linear infinite 2s; /* IE 10+, Fx 29+ */
}

.floating-icon-medium-far {
  position: absolute;
  -webkit-animation: floatIcon 8s linear infinite 4s; /* Safari 4+ */
  -moz-animation: floatIcon 8s linear infinite 4s; /* Fx 5+ */
  -o-animation: floatIcon 8s linear infinite 4s; /* Opera 12+ */
  animation: floatIcon 8s linear infinite 4s; /* IE 10+, Fx 29+ */
}

.floating-icon-far {
  position: absolute;
  -webkit-animation: floatIcon 8s linear infinite 6s; /* Safari 4+ */
  -moz-animation: floatIcon 8s linear infinite 6s; /* Fx 5+ */
  -o-animation: floatIcon 8s linear infinite 6s; /* Opera 12+ */
  animation: floatIcon 8s linear infinite 6s; /* IE 10+, Fx 29+ */
}

.bouncing-mouse {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
  -webkit-animation: bounce 0.8s linear infinite alternate; /* Safari 4+ */
  -moz-animation: bounce 0.8s linear infinite alternate; /* Fx 5+ */
  -o-animation: bounce 0.8s linear infinite alternate; /* Opera 12+ */
  animation: bounce 0.8s linear infinite alternate; /* IE 10+, Fx 29+ */
}

.clear {
  position: relative;
  width: 0;
  height: 0;
  display: block;
  clear: both;
}

.position-relative {
  position: relative;
}

.w100 {
  width: 100%;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.vertical-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.iam-container {
  position: absolute;
  top: 50%;
  margin-left: 250px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.text-align-center {
  text-align: center;
}

.pattern {
  position: absolute;
  bottom: -20px;
  left: -260px;
  color: #424242;
}

.dots-loading-container {
  z-index: 3;
  padding: 10px;
  background-color: #eee;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  margin: auto;
  opacity: 1;
  transition: 0.3s 0.1s;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
}

.dots-loading-container.animate {
  opacity: 0;
}

.loading .header {
  opacity: 0;
}

.loading .dave-area {
  opacity: 0;
}

.loading .bouncing-mouse {
  opacity: 0;
}

.bar-container {
  background-color: #bebebe;
  width: 100%;
  height: 13px;
  border-radius: 50px;
  position: relative;
  margin-top: 3px;
}

.bar-fill {
  background-color: #1f272b;
  position: relative;
  border-radius: 50px;
  height: 100%;
  transition: width 1s;
}

.bar-text-container {
  display: flex;
  flex-direction: row;
}

.bar-text-description {
  color: #a2a2a2;
  margin-left: 10px;
  font-size: 14px;
}

.bar-text {
  flex: 1 1 auto;
  color: #1f272b;
  text-align: left;
  font-weight: 600;
}

.bar-percentage {
  flex: 0 0 50px;
  color: #a2a2a2;
  text-align: right;
  font-weight: 600;
}

.circular-progress-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0px 15%;
}

.about-me-text {
  line-height: 24px;
  font-size: 17px;
}

.offers-card-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0px 15%;
  max-width: 1355px;
  margin: 0px auto;
}

.form.open .button.title {
  font-size: 17px;
}

.contacts-card-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0px 15%;
  width: 100%;
  box-sizing: border-box;
}

.divisor-1 {
  position: absolute;
  height: 210px;
  width: 120%;
  background-color: rgb(236, 236, 236);
  left: -20px;
  transform: rotate(-5deg);
}

.divisor-2 {
  position: absolute;
  width: 120%;
  height: 500px;
  margin-top: -500px;
  background-color: rgb(236, 236, 236);
  z-index: -1;
  transform: rotate(-5deg);
}

.timeline-columns-container {
  /* height: 1073px; */
  margin-bottom: 31px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  transition: all 0.8s;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: transparent;
}

.timeline.small-mode .timeline-scroller {
  width: 460px;
}

.timeline.small-mode .timeline-columns-container {
  cursor: pointer;
}

.timeline.small-mode.right .timeline-columns-container {
  /* justify-content: flex-end; */
  transform: translateX(-182px);
}

.timeline.small-mode.left .timeline-columns-container {
  /* justify-content: flex-start; */
  transform: translateX(183px);
}

.timeline.small-mode.right
  .timeline-scroller
  > div
  > div:nth-child(1)
  .timeline-card {
  opacity: 0;
}

.timeline.small-mode.left
  .timeline-scroller
  > div
  > div:nth-child(3)
  .timeline-card {
  opacity: 0;
}

.timeline-titles > div {
  font-size: 17px;
  color: rgb(66, 66, 66);
  /* margin-left: 30px; */
}

.timeline-titles {
  display: flex;
  flex-direction: row;
  width: 880px;
  margin: 0px auto 10px;
  justify-content: space-around;
  transition: all 0.8s;
}

.timeline-titles-container {
  width: 880px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.timeline.small-mode .timeline-titles > div:nth-child(1),
.timeline.small-mode .timeline-titles > div:nth-child(2) {
  transition: all 0.6s;
}

.timeline.small-mode.right .timeline-titles {
  /* margin-left: -213px; */
  transform: translateX(-110px);
}

.timeline.small-mode.left .timeline-titles {
  /* margin-left: 225px; */
  transform: translateX(113px);
}

.timeline.small-mode.right .timeline-titles > div:nth-child(1) {
  opacity: 0;
}

.timeline.small-mode.left .timeline-titles > div:nth-child(2) {
  opacity: 0;
}

.timeline.small-mode .timeline-titles-container {
  width: 460px;
}

.timeline.loaded-initial-anim .timeline-card {
  transition: all 0.6s;
}

.timeline.loaded-initial-anim .we-are-here {
  transition: all 0.8s;
}

.timeline-mode-button {
  position: absolute;
  display: block;
  top: 88px;
  font-size: 14px;
  color: #3caea3;
  height: 0;
  opacity: 0;
  cursor: default;
  user-select: none;
  transition: opacity 0.6s;
}

.timeline-mode-button.active {
  height: unset;
  cursor: pointer;
  opacity: 1;
}

.timeline-mode-button.left {
  left: 45px;
}

.timeline-mode-button.left:before {
  content: "<";
  margin-right: 5px;
}

.timeline-mode-button.right {
  right: 45px;
}

.timeline-mode-button.right:after {
  content: ">";
  margin-left: 5px;
}

.iam-container .title {
  font-size: 18px;
}

.iam-container .title.bold {
  margin-top: -10px;
  font-size: 44px;
}

.iam-container .title.iam {
  font-size: 20px;
  margin-top: 10px;
}

.left-square {
  width: 220px;
  height: 200px;
  left: -150px;
  top: 20px;
  position: absolute;
  background-color: rgb(166, 185, 224);
  z-index: 1;
  transform: translateX(-30%) rotate(70deg);
  animation: left-square 1.6s 1.5s;
}

.fullpage-container.loaded .left-square {
  transform: translateX(0) rotate(65deg);
}

.left-circle-timeline {
  width: 250px;
  height: 250px;
  left: -150px;
  top: 60px;
  border-radius: 50%;

  position: absolute;
  background-color: #d6dda6;
  opacity: 0;
  transform: translateX(-50px);
  opacity: 0;
  transition: 0.6s 0.7s;
}

.right-square-timeline {
  width: 220px;
  height: 200px;
  right: -150px;
  top: 310px;
  position: absolute;
  background-color: #9fc1c2;
  opacity: 0;
  transform: translateX(50px) rotate(52deg);
  opacity: 0;
  transition: 0.6s 0.7s;
}

.right-circle-timeline {
  width: 50px;
  height: 50px;
  right: 100px;
  top: 260px;
  border-radius: 50%;
  position: absolute;
  background-color: #b7bdeb;
  transform: scale(0);
  opacity: 0;
  transition: 0.6s 0.7s;
}

.timeline-container.animate .left-circle-timeline {
  opacity: 1;
  transform: translateX(0px);
}
.timeline-container.animate .right-square-timeline {
  opacity: 1;
  transform: translateX(0px) rotate(62deg);
}
.timeline-container.animate .right-circle-timeline {
  transform: scale(1);
  opacity: 1;
}

.gallery-previewer-content video,
.gallery-previewer-content img {
  vertical-align: middle;
  max-height: 620px;
}

.gallery-previewer-content > div {
  flex: 1 1 auto;
  position: relative;
}

.gallery-previewer-watch-video {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #6f6f6fb5;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.gallery-previewer-close {
  position: absolute;
  right: 20px;
  margin-top: -1px;
  display: block;
  cursor: pointer;
}

.gallery-previewer-close:after {
  content: "";
  width: 20px;
  height: 4px;
  position: relative;
  background-color: #f3f3f3;
  transform: rotate(45deg);
  display: block;
}

.gallery-previewer-close:before {
  content: "";
  width: 20px;
  height: 4px;
  position: relative;
  background-color: #f3f3f3;
  transform: rotate(-45deg);
  display: block;
  right: 0px;
  top: 4px;
}

.gallery-previewer-watch-video div {
  font-weight: 600;
}

.submit-button {
  background-color: #f9f9f9;
  /* width: 130px; */
  /* height: 40px; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.submit-button.success,
.submit-button.error {
  cursor: default;
}

.email-icon {
  width: 25px;
  transform: rotate(45deg) translate3d(0px, -3px, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: transform 0.2s ease-in-out;
  opacity: 1;
}

/* .submit-button:hover > .email-icon {
  transition: transform .2s .1s ease-in-out;
  transform: rotate(40deg) translate3d(0, 7px, 0);
} */

@media (hover: hover) {
  .submit-button:hover > .email-icon {
    transition: transform 0.2s 0.1s ease-in-out;
    transform: rotate(40deg) translate3d(0, 7px, 0);
  }
}

.submit-button.loading > .email-icon {
  transition: transform 0.3s 0.1s ease-in-out;
  transform: rotate(35deg) translate3d(0, -50px, 0);
}

.submit-button.loading.success > .email-icon,
.submit-button.loading.error > .email-icon {
  opacity: 0;
  transform: rotate(45deg) translate3d(0, 50px, 0);
}

.submit-button.success > .email-icon,
.submit-button.error > .email-icon {
  transition: transform 0.4s ease-in-out;
}

.submit-button .status {
  width: 50px;
  height: 50px;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: transform 1s;
  opacity: 1;
  transform: translateY(-50px);
}

.submit-button .status .email-icon {
  width: 15px;
  transform: rotate(180deg);
  transition: transform 0.8s 0.6s, opacity 0.8s 0.6s;
}

.submit-button.success .status,
.submit-button.error .status {
  transform: translateY(0);
}

.submit-button.success .status .email-icon,
.submit-button.error .status .email-icon {
  transform: rotate(180deg) scale(0);
  opacity: 0;
}

.submit-button .status .status-dot {
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  display: block;
  border: 0px solid #424242;
  border-radius: 50%;
  border-width: 5px;
  transition: transform 0.8s 0.6s, background-color 0.3s 1s,
    border-color 0.2s 1.1s;
  background-color: #424242;
  transform: scale(0);
}

.submit-button.success .status .status-dot,
.submit-button.error .status .status-dot {
  transform: scale(1);
  background-color: unset;
}

.submit-button.success .status-dot .status-dot-line-1 {
  position: absolute;
  width: 10px;
  height: 5px;
  top: 12px;
  left: 4px;
  transform: rotate(45deg);
  background-color: #424242;
  transition: background-color 0.2s 1.1s;
  border-radius: 50px;
}

.submit-button.success .status-dot .status-dot-line-2 {
  position: absolute;
  width: 16px;
  height: 5px;
  top: 11px;
  left: 8px;
  transform: rotate(-45deg);
  background-color: #424242;
  transition: background-color 0.2s 1.1s;
  border-radius: 50px;
}

.submit-button.error .status-dot .status-dot-line-1 {
  position: absolute;
  width: 18px;
  height: 5px;
  top: 11px;
  left: 4px;
  transform: rotate(45deg);
  background-color: #424242;
  transition: background-color 0.2s 1.1s;
  border-radius: 50px;
}

.submit-button.error .status-dot .status-dot-line-2 {
  position: absolute;
  width: 18px;
  height: 5px;
  top: 11px;
  left: 4px;
  transform: rotate(-45deg);
  background-color: #424242;
  transition: background-color 0.2s 1.1s;
  border-radius: 50px;
}

.submit-button.success .status .status-dot {
  border-color: #3a923a;
}

.submit-button.success .status-dot .status-dot-line-1,
.submit-button.success .status-dot .status-dot-line-2 {
  background-color: #3a923a;
}

.submit-button.error .status .status-dot {
  border-color: #f14747;
  animation: submit-button-leave 1s 1.8s;
}

.submit-button.error .status-dot .status-dot-line-1,
.submit-button.error .status-dot .status-dot-line-2 {
  background-color: #f14747;
}

textarea.error,
input.error {
  box-shadow: 0px 3px 0px 0px #ff3628;
}

.pic-container.pause .floating-item {
  animation-play-state: paused;
}

.languages-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.switch-button {
  position: relative;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.switch-button div {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  background-color: #f9f9f9;
  color: #424242;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid #f9f9f9;
  transition: 0.4s;
}

.switch-button div:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.switch-button div:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.switch-button div.selected,
.switch-button div:hover {
  background-color: #424242;
  color: #f9f9f9;
}

@media only screen and (max-width: 1700px) {
  .pic-container {
    right: -100px;
  }
}

@media only screen and (max-width: 1600px) {
  .pic-container {
    width: 676px;
    height: 796px;
  }
}

@media only screen and (min-width: 1400px) {
  .gallery-previewer-container {
    /* width: 1260px;
    height: 797px; */
    width: 1260px;
    height: 797px;
    margin: auto;
  }

  .iam-container .title {
    font-size: 22px;
  }

  .iam-container .title.bold {
    font-size: 50px;
  }
  .iam-container .title.iam {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1250px) {
  .iam-container {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1150px) and (min-width: 1000px) {
  .menu {
    padding-left: 70px;
  }
  .menu .menu-item {
    font-size: 14px;
    margin-left: 40px;
  }
}

@media only screen and (max-width: 1100px) {
  .counter-container-section {
    padding: 0;
  }
  .contacts-card-container {
    padding: 0 15px;
  }
  .offers-card-container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1050px) {
  .timeline {
    width: 90%;
    box-sizing: border-box;
  }
  .iam-container {
    top: 75%;
    margin-left: 50px;
  }
}

@media only screen and (max-width: 930px) {
  .about-me-container {
    width: 90%;
  }

  .gallery-previewer-container {
    height: 90%;
  }

  .gallery-previewer-info .col {
    flex: unset;
  }
}

@media only screen and (max-width: 850px) {
  .gallery {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 700px) {
  .title {
    font-size: 19px;
  }
  .divisor-1 {
    transform: rotate(-8deg);
  }
  .divisor-2 {
    transform: rotate(-8deg);
  }
  .about-me-text {
    font-size: 15px;
  }
  .button.title {
    font-size: 13px;
  }
  .offers-card .title {
    font-size: 15px;
  }
  .counter-number {
    font-size: 21px;
  }
  .counter-text {
    font-size: 15px;
  }
  .counter-container-section {
    padding: 0;
  }
  .gallery {
    padding: 0 15px;
  }
  .form.open {
    width: 90%;
  }
}

@media only screen and (max-width: 630px) {
  .timeline-card {
    width: 250px;
    height: 188px;
  }
  .timeline.small-mode .timeline-titles-container {
    width: 200px;
  }
  .timeline.small-mode .timeline-scroller {
    width: 200px;
  }
  .timeline.small-mode.left .timeline-columns-container {
    transform: translateX(143px);
  }
  .timeline.small-mode.right .timeline-columns-container {
    transform: translateX(-142px);
  }
  .timeline.small-mode.right .timeline-titles {
    /* margin-left: -213px; */
    transform: translateX(-63px);
  }

  .timeline.small-mode.left .timeline-titles {
    /* margin-left: 225px; */
    transform: translateX(71px);
  }
}

@media only screen and (max-width: 590px) {
  .pic-container {
    width: 495px;
    height: 583px;
    right: -50px;
  }
  svg.floating-item {
    width: 35px;
  }
  .left-square {
    width: 200px;
    height: 170px;
    left: -140px;
  }
  .hamburguer-button {
    left: 60px;
  }
}

@media only screen and (max-width: 550px) {
  .iam-container .title,
  .iam-container .title.iam {
    font-size: 15px;
  }
  .iam-container .title.bold {
    font-size: 30px;
    margin-top: -5px;
  }
  .iam-container {
    top: 67%;
  }
  .text-cursor {
    height: 20px;
    top: 4px;
  }
}

@media only screen and (max-width: 500px) {
  .pic-container {
    width: 405px;
    height: 477px;
  }
  .left-square {
    width: 160px;
    height: 130px;
    left: -130px;
  }
  .hamburguer-button {
    left: 40px;
  }
  .timeline-mode-button.left {
    left: 15px;
  }
  .timeline-mode-button.right {
    right: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .menu {
    padding-left: 50px;
  }
  .menu.hamburguer-mode.open {
    height: 356px;
  }
  .menu.hamburguer-mode .menu-item:nth-child(2) {
    margin-top: 40px;
  }
  .iam-container {
    margin-left: 25px;
  }
  .small-mode.right .we-are-here {
    /* margin-left: 75px; */
    transform: translate3d(35px, 0, 0);
  }

  .small-mode.left .we-are-here {
    /* margin-right: 75px; */
    transform: translate3d(-35px, 0, 0);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 500px;
  background: #eaeaea;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 500px;
  background: #adadad;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #797979;
}

@-webkit-keyframes flashColor {
  0% {
    background-color: #5d5d5d;
  }
  49% {
    background-color: #5d5d5d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@-moz-keyframes flashColor {
  0% {
    background-color: #5d5d5d;
  }
  49% {
    background-color: #5d5d5d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@-o-keyframes flashColor {
  0% {
    background-color: #5d5d5d;
  }
  49% {
    background-color: #5d5d5d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes flashColor {
  0% {
    background-color: #5d5d5d;
  }
  49% {
    background-color: #5d5d5d;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

@-webkit-keyframes floatIcon {
  0% {
  }
  100% {
    top: -20%;
  }
}
@-moz-keyframes floatIcon {
  0% {
  }
  100% {
    top: -20%;
  }
}
@-o-keyframes floatIcon {
  0% {
  }
  100% {
    top: -20%;
  }
}
@keyframes floatIcon {
  0% {
  }
  100% {
    top: -20%;
  }
}

/* @-webkit-keyframes fullscreen {
  0%   { transform: scale(1.1) translateY(50px); opacity: 0; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
}
@-moz-keyframes fullscreen {
  0%   { transform: scale(1.1) translateY(50px); opacity: 0; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
}
@-o-keyframes fullscreen {
  0%   { transform: scale(1.1) translateY(50px); opacity: 0; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
}
@keyframes fullscreen {
  0%   { transform: scale(1.1) translateY(50px); opacity: 0; }
  100% { transform: scale(1) translateY(0); opacity: 1; }
} */

@-webkit-keyframes portfolioItem {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-moz-keyframes portfolioItem {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes portfolioItem {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes portfolioItem {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes pic-animation-right {
  0% {
    transform: translateX(10%) rotate(-1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}
@-moz-keyframes pic-animation-right {
  0% {
    transform: translateX(10%) rotate(-1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}
@-o-keyframes pic-animation-right {
  0% {
    transform: translateX(10%) rotate(-1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}
@keyframes pic-animation-right {
  0% {
    transform: translateX(10%) rotate(-1deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@-webkit-keyframes left-square {
  0% {
    transform: translateX(-30%) rotate(70deg);
  }
  100% {
    transform: translateY(0) rotate(65deg);
  }
}
@-moz-keyframes left-square {
  0% {
    transform: translateX(-30%) rotate(70deg);
  }
  100% {
    transform: translateY(0) rotate(65deg);
  }
}
@-o-keyframes left-square {
  0% {
    transform: translateX(-30%) rotate(70deg);
  }
  100% {
    transform: translateY(0) rotate(65deg);
  }
}
@keyframes left-square {
  0% {
    transform: translateX(-30%) rotate(70deg);
  }
  100% {
    transform: translateY(0) rotate(65deg);
  }
}

@-webkit-keyframes gallery-image {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-moz-keyframes gallery-image {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes gallery-image {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes gallery-image {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes galleryPreviewContainer {
  from {
    transform: scale(0.8) translate3d(-50%, -40%, 0);
    opacity: 0.5;
  }
  to {
    transform: scale(1) translateY(-50%, -50%, 0);
    opacity: 1;
  }
}
@-moz-keyframes galleryPreviewContainer {
  from {
    transform: scale(0.8) translate3d(-50%, -40%, 0);
    opacity: 0.5;
  }
  to {
    transform: scale(1) translateY(-50%, -50%, 0);
    opacity: 1;
  }
}
@-o-keyframes galleryPreviewContainer {
  from {
    transform: scale(0.8) translate3d(-50%, -40%, 0);
    opacity: 0.5;
  }
  to {
    transform: scale(1) translateY(-50%, -50%, 0);
    opacity: 1;
  }
}
@keyframes galleryPreviewContainer {
  from {
    transform: scale(0.8) translate3d(-50%, -40%, 0);
    opacity: 0.5;
  }
  to {
    transform: scale(1) translateY(-50%, -50%, 0);
    opacity: 1;
  }
}

@-webkit-keyframes submit-button-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-70px);
    opacity: 0;
  }
}
@-moz-keyframes submit-button-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-70px);
    opacity: 0;
  }
}
@-o-keyframes submit-button-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-70px);
    opacity: 0;
  }
}
@keyframes submit-button-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-70px);
    opacity: 0;
  }
}
